.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 45px 0px;
  border-top: 1px solid var(--wine);
  width: 100%;

  &-link {
    font-style: italic;
    font-weight: 600;
    text-decoration: underline;
    padding-bottom: 15px;
  }
  &-text {
    font-weight: 500;
  }
}
