.headlines {
  gap: 30px;
  max-width: 75vw;

  :first-child {
    text-align: center;
    font-size: 1.5rem;
    gap: 30px;
  }
}
