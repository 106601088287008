.taglines {
    max-width: 75vw;

    .tagline {
        &-icon {
            margin-bottom: 25px;
        }

        text-align: center;
        font-size: 2rem;
    }

    .dots{
        margin: 105px 0;
    }
}