@import url("https://fonts.googleapis.com/css2?family=Livvic:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

:root {
  --lightcream: #fff9ef;
  --cream: #fff0d899;
  --wine: #661814;
}

a {
  text-decoration: none;
  color: inherit;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

/* FONT SIZING */
h1 {
  font-size: 2.5rem;
  margin: 0;
}

h2 {
  font-size: 2rem;
  margin: 25px 0;
}

h3 {
  font-size: 1.75rem;
  margin: 0;
}

h4 {
  font-size: 1.5rem;
  margin: 0;
}

h5 {
  font-size: 1.25rem;
  margin: 0;
}

h6 {
  font-size: 1rem;
  margin: 0;
}

p {
  font-size: 1rem;
}

input {
  outline: none;
  accent-color: var(--wine);
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: "Livvic", sans-serif;
  background-color: var(--lightcream);
  color: var(--wine);
}

// Global Classes
.flx-clmn-cntr {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bold-span {
  span {
    font-style: italic;
    font-weight: bold;
  }
}

.border-main {
  border: 1.5px solid var(--wine);
  border-radius: 55px;
}
