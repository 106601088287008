.header-container {
    width: 100vw;
    display: flex;
    justify-content: center;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 45px 30px;
        width: 80vw;
    }
}