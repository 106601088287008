.key-features {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 80px;
  margin: 45px 0;

  .key-feature {
    font-size: 20px;
    background-color: var(--cream);
    color: var(--wine);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    height: 220px;
    width: 220px;
    &-icon {
      margin-bottom: 25px;
    }
    &-text {
      width: 200px;
    }

    span {
      font-style: italic;
      font-weight: 600;
    }
  }
}
