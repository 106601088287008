.signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-header {
    display: flex;
    font-size: 24px;
    padding-bottom: 25px;
    &-text {
      font-style: italic;
      font-weight: 600;
      padding-right: 7px;
    }
  }

  &-form {
    background-color: var(--cream);
    margin-top: 30px;
    padding: 40px;
    width: 230px;

    &-input {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      color: black;
      font-weight: 600;
      div {
        font-size: 15px;
        margin-bottom: 10px;
      }
      input {
        font-size: 16px;
        width: calc(100% - 18px);
        border: 1.5px solid var(--wine);
        border-radius: 10px;
        background-color: var(--lightcream);
        padding: 8px;
        padding-left: 10px;
        margin-bottom: 30px;
      }
    }
    &-checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      font-size: 13px;
      margin-bottom: 30px;
      font-weight: 500;
      width: 280px;
      margin-left: 30px;
      cursor: pointer;

      input {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin-right: 12px;
      }
    }
  }
  &-submitted {
    font-size: 20px;

    &-text {
      text-align: center;
      margin-top: 20px;
      width: 200px;
    }

    span {
      font-style: italic;
      font-weight: 600;
    }
  }
}
