.button {
  background-color: var(--wine);
  color: var(--lightcream);
  padding: 0.75rem 1.5rem;
  border-radius: 5rem;
  border: none;
  font-family: inherit;
  font-size: 16px;
  cursor: pointer;
}
